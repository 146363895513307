<template>
  <div class="woocommerce-mini-cart-item cart-item cart_item d-flex flex-wrap justify-content-start">
    <a
        @click="updateQuantity(item.sku,0)"
        href="javascript:void(0)"
        class="remove remove_from_cart_button product-remove d-flex align-items-center"
        style="font-size: 30px; margin-left: 15px"
    >&times;</a
    >

    <NuxtLink class="product-thumbnail" :to="item?.uri">
      <wp-image :img="cartImage"/>
    </NuxtLink>
    <div class="cart-item__inner pr-3 d-flex flex-wrap align-items-center justify-content-between">
      <div class="cart-item__right">
        <div class="product-name" style="width: 100%">{{ itemData?.mat_type || item?.product?.name }}</div>

        <div class="cart_item_meta">
          <div class="sku">{{ `מק״ט: ${item?.sku}` }}</div>
          <template v-if="type == 'mat'">

            <div class="car_make">{{ `${item?.make} ${item?.model} ${item?.data?.car_year || ""}` }}</div>

            <div class="meta" v-for="attr in item.data.selected_meta">
              {{ `${attr.label}: ${_ToUpper(attr.value)}` }}
            </div>

            <div class="car_year d-none"></div>

            <div class="selected_set">{{ `סט: ${item.data?.selected_options?.label}` }}</div>

            <div class="mat_color" v-if="productCategory == 21">{{ `צבע השטיח: ${matColors?.matColor?.label}` }}</div>
            <div class="border_color" v-if="productCategory == 21">{{
                `צבע המסגרת: ${matColors?.matBorderColor?.label}`
              }}
            </div>
          </template>
          <template v-if="type == 'color'">
            <div class="mat_color">{{ `צבע השטיח: ${matColors?.matColor?.label}` }}</div>
            <div class="border_color">{{ `צבע המסגרת: ${matColors?.matBorderColor?.label}` }}</div>
          </template>
          <template v-if="type == 'var'">
            <div class="mat_color" v-if="item?.product?.productFields?.titelForVarSelect">
              {{ `${item?.product?.productFields?.titelForVarSelect} ${matColors?.matColor?.label}` }}
            </div>
            <div class="mat_color" v-else>{{ `צבע : ${matColors?.matColor?.label}` }}</div>
            <div class="seat_type"></div>
            <template v-if="isKeychain">
              <template v-if="itemData.keychain.hasSideB">צד א׳:</template>
              <div class="mat_color"> {{` ${itemData.keychain.label}:${itemData.keychain.value} `}}</div>
              <template v-if="itemData.keychain.hasSideB">צד ב׳:</template>
              <div class="mat_color" v-if="itemData.keychain.hasSideB"> {{` ${itemData.keychain.sideB.label}:${itemData.keychain.sideB.value} `}}</div>

            </template>
          </template>
        </div>
      </div>
      <div class="cart-item__left d-flex justify-content-between flex-row-reverse align-items-center">
        <div class="product-price">
          <del v-if="itemFullPrice"> ₪{{ itemFullPrice }}</del>
          <span> ₪{{ itemTotal }}</span>
        </div>


        <div class="product-quantity d-flex justify-content-between align-items-end" data-title="">
          <div class="quantity d-inline-flex align-items-center">
            <label class="quantity-label" for="">כמות:</label>
            <v-text-field
                hide-details
                class="text-center"
                v-model="itemQuantity"
                type="text"
                variant="outlined"
                :density="'compact'"
                flat

            >
              <template #prepend>
                <button type="button" class="qty_minus" @click="subQuantity">
                  <nuxt-img src="/images/svg/minus.svg" alt=""/>
                </button>
              </template>
              <template #append>
                <button type="button" class="qty_plus" @click="addQuantity">
                  <nuxt-img src="/images/svg/plus.svg" alt=""/>
                </button>
              </template>
            </v-text-field>

            <!-- <input type="number" @change="updateQ" min="0" v-model="itemQuantity" size="2" /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: false,
  },
  type: {
    type: String,
    required: false,
  },
});
const itemQuantity = ref(props.item.quantity);
const {options} = useGlobalStore();
const {matsCartImages} = options;

const productCategory = props?.item?.product?.productCategories?.nodes[0]?.databaseId;

const {removeItem, updateItemQuantity} = useCart();
const {updateQuantity} = useCartStore();

watch(
    () => itemQuantity.value,
    updateQuantity(props.item.sku, itemQuantity.value)
);
const addQuantity = () => {
  itemQuantity.value++;
  updateQuantity(props.item.sku, itemQuantity.value);
};
const subQuantity = () => {
  itemQuantity.value--;
  updateQuantity(props.item.sku, itemQuantity.value);
};

const itemData = computed(() => {
  const data = _CloneDeep(props.item.data);
  data['mat_type'] = props.type === 'mat' ? props.item?.product.productCategories.nodes[0]?.name : '';
  return data;
});
const isKeychain = computed(() => {
  if (!_IsEmpty(itemData.value?.keychain)){
    return true;
  }
  return false;
});

const matColors = computed(() => {
  return {
    matColor: props.item?.data?.mat_color,
    matBorderColor: props.item?.data?.mat_border_color,
  };

  // const findColors = props.item?.extraData.filter(
  //     (data) => data.key === "mat_color" || data.key === "mat_border_color"
  // );
  //
  // return _Reduce(
  //     findColors,
  //     function (result, value, key) {
  //       if (value.key === "mat_color") {
  //         result.matColor = JSON.parse(value.value);
  //       } else if (value.key === "mat_border_color") {
  //         result.matBorderColor = JSON.parse(value.value);
  //       }
  //       return result;
  //     },
  //     {}
  // );
});
const cartImage = computed(() => {
  let images = matsCartImages.filter((item) => item.type?.nodes[0]?.databaseId == productCategory);
  if (_IsEmpty(images)) {
    images = matsCartImages.filter((item) => item.type?.nodes[0]?.databaseId == 15);
  }
  if (props.type == "mat") {
    const findImage = images?.find(
        (image) =>
            image.mainColor == matColors?.value?.matColor?.value &&
            image.borderColor == matColors?.value?.matBorderColor?.value
    );
    return findImage?.img?.node;
  }
  if (props.type == "var") {
    let image = images.find((item) => item.mainColor == props.item.sku);
    return image?.img?.node;
  }
  let image = images.find((item) => item.mainColor == props?.item?.id);
  return image?.img?.node;
  return props?.item.product?.image;
});
const selectedOptionLabel = (value) => {
  const selections = props.item?.product?.productFields?.selectionTypes;
  const findOption = selections?.find((option) => option.type[0] === value);
  return findOption?.type[1];
};

const itemTotal = computed(() => {
  if (props.item.data?.trunk_discount == 1 && props.item.fullPrice) {
    return (props.item.price * 1) + (props.item.fullPrice * (props.item.quantity - 1));
  } else {
    return props.item.price * props.item.quantity;

  }
  //return props.item.quantity * props.item.price;
});
const itemFullPrice = computed(() => {
  if (props.item.fullPrice) {
    return props.item.fullPrice * props.item.quantity;
  }
  return null;
});
</script>
<style lang="scss">
.cart_item {
  .v-field__input {
    @media screen and (max-width: 800px) {
      text-align: center;
      min-height: 10px !important;
      padding: 2px 10px;
    }
  }

  .product-thumbnail {
    @media screen and (max-width: 800px) {
      display: flex;
      align-items: center;
    }

    img {
      @media screen and (max-width: 800px) {
        max-width: 50px;
      }
    }
  }

  .remove_from_cart_button {
    @media screen and (max-width: 800px) {
      font-size: 30px !important;

    }

  }
}
</style>
